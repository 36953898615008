import Country from '@/locations/models/country'

class CountriesService {

  // TODO: Aus der DB laden.
  public findAllAsync(): Country[] {
    return [
      { code: 'AT', name: 'Österreich' },
      { code: 'DE', name: 'Deutschland' },
      { code: 'IT', name: 'Italien' },
      { code: 'LI', name: 'Liechtenstein' },
      { code: 'CH', name: 'Schweiz' },
      { code: 'SK', name: 'Slowakei' },
      { code: 'SI', name: 'Slowenien' },
      { code: 'CZ', name: 'Tschechien' },
      { code: 'HU', name: 'Ungarn' },
    ]
  }
}

export default new CountriesService()
