import Arrays from "@/app/utils/arrays";
import Dates from "@/app/utils/dates";
import Customer from "@/customers/entities/customer";
import customersService from "@/customers/services/customers-service";
import Invoice from "@/invoices/models/invoice";
import { InvoiceState } from "@/invoices/models/invoice-state";
import { PaymentMethod } from "@/invoices/models/payment-method";
import RidesMultiselectDialog from "@/rides/components/rides-multiselect-dialog/rides-multiselect-dialog";
import Ride from "@/rides/models/ride";
import ridesService from "@/rides/services/rides-service";
import cloneDeep from "lodash/cloneDeep";
import moment, { duration } from "moment";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import Guid from "@/app/utils/guid";
import Rides from "@/rides/utils/rides";
import { RuleResult } from "vee-validate";

@Component
export default class InvoicesDialog extends Vue {
  @Ref()
  private ridesMultiselectDialog!: RidesMultiselectDialog;

  private invoiceStateItems = [
    { value: InvoiceState.Pending, text: "offen" },
    { value: InvoiceState.Canceled, text: "storniert" },
    { value: InvoiceState.Paid, text: "bezahlt" },
    { value: InvoiceState.FirstDunning, text: "erste Mahnung" },
    { value: InvoiceState.SecondDunning, text: "zweite Mahnung" },
    { value: InvoiceState.ThirdDunning, text: "dritte Mahnung" },
    { value: InvoiceState.CreditNote, text: "Gutschrift" },
  ];

  private paymentMethodItems = [
    { value: PaymentMethod.Cash, text: "Bar" },
    { value: PaymentMethod.CreditCard, text: "Kreditkarte" },
    { value: PaymentMethod.Invoice, text: "Rechnung" },
  ];

  private dialog: boolean = false;
  private editMode: boolean = false;
  private invoice: Invoice = new Invoice();
  private customer: Customer | null = null;
  private selectedRides: Ride[] = [];

  private date = "";
  private datePickerMenu: boolean = false;

  private jobNr: string | null = null;

  private get dialogTitle(): string {
    return `${
      this.invoice.invoiceState == InvoiceState.CreditNote
        ? "Gutschrift"
        : "Rechnung"
    } ${this.invoice.isNew ? "hinzufügen" : "bearbeiten"} ${
      this.invoice.invoiceNo ? "| " + this.invoice.invoiceNo : ""
    }`;
  }

  get isReminder() {
    return (
      this.invoice.invoiceState == InvoiceState.FirstDunning ||
      this.invoice.invoiceState == InvoiceState.SecondDunning ||
      this.invoice.invoiceState == InvoiceState.ThirdDunning
    );
  }

  // get jobNr() {
  //   if (this.selectedRides !== null) {
  //     if (this.selectedRides.length < 2) {
  //       return this.selectedRides
  //         .map((r) => r.jobNr)
  //         .join(', ')
  //     }
  //     return `Sammelrechnung (${this.selectedRides.length})`
  //   }
  //   return 'Aufträge auswählen'
  // }

  public async create(invoice?: Invoice) {
    this.invoice = invoice || new Invoice();
    this.setDate();
    this.customer = null;
    this.selectedRides = [];
    this.jobNr = null;
    this.dialog = true;
    this.editMode = false;
    this.$validator.reset();
  }

  public async createFromRide(ride: Ride) {
    var rides = [ride];

    this.invoice = new Invoice();
    this.setDate();
    this.customer = ride.customer;
    this.selectedRides = rides;
    this.jobNr = null;
    this.dialog = true;
    this.editMode = false;
    this.$validator.reset();
    this.jobNr = this.getJobDescription();
  }

  public async edit(invoice: Invoice) {
    this.invoice = cloneDeep(invoice);
    this.setDate();
    this.customer = invoice.customer;
    this.selectedRides = await this.loadRidesAsync(invoice.id);
    this.jobNr = this.getJobDescription();
    this.dialog = true;
    this.editMode = true;
    this.$validator.reset();
  }

  private loadCustomerAsync(customerId: string | null) {
    return customersService.findOneAsync(customerId);
  }

  private loadRidesAsync(invoiceId: string) {
    return ridesService.findAllForInvoiceAsync(invoiceId);
  }

  private openRideMultiselectDialog() {
    this.ridesMultiselectDialog.open(this.selectedRides);
  }

  private getJobDescription() {
    if (this.selectedRides !== null) {
      if (this.selectedRides.length === 0) {
        return null;
      }
      if (this.selectedRides.length < 2) {
        const jobs = this.selectedRides.map((r) => r.jobNr).join(", ");

        if (jobs) {
          return jobs;
        }
        return `Auftrag (${this.selectedRides.length})`;
      }
      return `Sammelrechnung (${this.selectedRides.length})`;
    }
    return null;
  }

  private selectRides(selected: Ride[]) {
    this.selectedRides = selected;
    this.jobNr = this.getJobDescription();
    if (this.customer === null) {
      if (this.haveSameCustomer(selected)) {
        this.customer = selected[0].customer;
      }
    }
  }

  private haveSameCustomer(selected: Ride[]) {
    if (selected.length > 0) {
      const customer = selected[0].customer;
      if (customer) {
        return selected.every(
          (r) => r.customer && r.customer.id === customer.id
        );
      }
    }
    return false;
  }

  private openInvoicePreview() {
    this.$emit("preview", this.invoice);
    this.dialog = false;
  }

  private createCreditNote() {
    if (
      confirm(
        "Möchten Sie wirklich eine Gutschrift für diese Rechnung erstellen? Die Rechnung kann anschließend nicht mehr geändert werden und bekommt den Status storniert."
      )
    ) {
      this.$emit("createCreditNote", this.invoice);
    }
    this.dialog = false;
  }

  private async save() {
    if (await this.$validator.validateAll()) {
      if (this.customer) {
        this.invoice.customerId = this.customer.id;
      }

      // Set the invoice date.
      this.invoice.date = this.date;

      // Rides that already exist in the DB will be assigned their IDs to rides.
      this.invoice.rides = this.selectedRides
        .filter((r) => r.isTemplate === false)
        .map((r) => r.id);

      // Non-existing Rides need to be created beforehand.
      // Turn a recurring ride into a regular ride.
      this.invoice.ridesToCreate = this.selectedRides
        .filter((r) => r.isTemplate)
        .map(Rides.toRegularRide);

      // set reminderCharges = 0 if not reminder
      if (!this.isReminder) {
        this.invoice.reminderCharges = 0;
      }

      // Copy their IDs
      this.$emit(this.editMode ? "update" : "save", this.invoice);
      this.dialog = false;
    }
  }

  private cancel() {
    this.$emit("cancel");
    this.dialog = false;
  }

  private setDate() {
    const mom = moment(this.invoice.date);
    this.date = mom.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS);
  }

  // private created() {
  //   const self = this

  //   this.$validator.extend('requiresRides', {

  //     getMessage(): string {
  //       return 'Bitte wählen Sie mindestens einen Auftrag aus.'
  //     },

  //     validate(): RuleResult {
  //       return self.selectedRides !== null && self.selectedRides.length > 0
  //     },
  //   })
  // }
}
