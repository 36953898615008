import store from '@/app/store'
import Customer from '@/customers/entities/customer'
import customersService from '@/customers/services/customers-service'
import Invoice from '@/invoices/models/invoice'
import invoicesService from '@/invoices/services/invoices-service'
import Ride from '@/rides/models/ride'
import ridesService from '@/rides/services/rides-service'
import Tenant from '@/tenants/entities/tenant'
import tenantsService from '@/tenants/services/tenants-service'
import AuthModule from '@/users/store/auth-module'
import { Component, Mixins, Prop, Ref, Vue, Watch } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import XConfirmDialog from '@/common/components/x-confirm-dialog/x-confirm-dialog'

@Component
export default class InvoicePreview extends Vue {

  @Prop()
  private invoiceId!: string

  @Ref()
  private printConfirmDialog!: XConfirmDialog

  private invoice: Invoice = new Invoice()
  private customer: Customer = new Customer()
  private tenant: Tenant = new Tenant()
  private rides: Ride[] = []
  private loading: boolean = false
  private generating = false
  private logoUrl = ''

  private async fetch(id: string) {
    this.loading = true
    const invoice = await invoicesService.findOneAsync(id)
    if (invoice) {
      this.invoice = invoice
      if (this.invoice.customer) {
        this.customer = this.invoice.customer
      }
      const tenant = await tenantsService.findOneAsync(this.invoice.tenantId)
      if (tenant) {
        this.tenant = tenant
      }
      this.rides = await ridesService.findAllForInvoiceAsync(id)
    }
    this.loading = false
  }

  private back() {
    this.$router.go(-1)
  }

  private async print() {
    if (this.invoice.isArchived) {
      this.printConfirmed()
    } else {
      this.printConfirmDialog.open()
    }
  }

  private async printConfirmed() {
    this.generating = true
    const pdfUrl = await invoicesService.generatePdfAsync(this.invoice.id)
    window.open(pdfUrl)
    // Rechnung neu laden. Sonst poppt der Dialog erneut auf.
    this.fetch(this.invoiceId)
    this.generating = false
  }

  private async send() {

  }

  private mounted() {
    this.fetch(this.invoiceId)

    const authModule = getModule(AuthModule, store)
    this.logoUrl = `${process.env.VUE_APP_API_URL}/tenants/logo?id=${authModule.tenant}&${new Date().getTime()}`
  }
}
