import Arrays from '@/app/utils/arrays'
import Guid from '@/app/utils/guid'
import Itinerary from '@/itineraries/models/itinerary'
import ItineraryLeg from '@/itineraries/models/itinerary-leg'
import TaxRate from '@/itineraries/models/tax-rate'
import cloneDeep from 'lodash/cloneDeep'
import { RuleResult } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ItineraryDialog extends Vue {

  @Prop({ default: () => [] })
  private rates!: TaxRate[]

  private shares = Arrays
    .range(21, 5)
    .map((v) => ({ text: `${v}%`, value: v }))

  private dialog: boolean = false
  private editMode: boolean = false
  private itinerary: Itinerary = new Itinerary()

  get filledLegs() {
    return this.itinerary.legs.filter((l) =>
      l.taxRateId && l.taxRateId !== Guid.empty())
  }

  public create(itinerary?: Itinerary) {
    this.itinerary = itinerary || new Itinerary()
    this.addDefaultRate(this.itinerary)
    this.dialog = true
    this.editMode = false
    this.$validator.reset()
  }

  public edit(itinerary: Itinerary) {
    this.itinerary = cloneDeep(itinerary)
    this.itinerary.legs.push(new ItineraryLeg())
    this.dialog = true
    this.editMode = true
    this.$validator.reset()
  }

  public async save() {
    if (await this.$validator.validateAll()) {
      this.itinerary.legs = this.filledLegs
      this.$emit(this.editMode ? 'update' : 'save', this.itinerary)
      this.dialog = false
    }
  }

  public delete_() {
    this.$emit('delete', this.itinerary)
    this.dialog = false
  }

  public cancel() {
    this.$emit('cancel')
    this.dialog = false
  }

  private async onChange() {
    const emptyLegs = this.itinerary.legs.filter((l) =>
      l.taxRateId === Guid.empty())
    if (emptyLegs.length === 0) {
      this.itinerary.legs.push(new ItineraryLeg())
    } else if (emptyLegs.length > 1) {
      this.itinerary.legs = this.filledLegs
      this.itinerary.legs.push(new ItineraryLeg())
    }
    await this.$validator.reset()
    await this.$validator.validateAll()
  }

  private addDefaultRate(itinerary: Itinerary) {
    const rateAT = this.rates.find((r) => r.countryId === 'AT')
    if (rateAT && itinerary.legs.length === 0) {
      const leg = new ItineraryLeg()
      leg.taxRateId = rateAT.id
      leg.taxRate = rateAT
      leg.share = 100
      this.itinerary.legs.push(leg)
    }
  }

  private created() {
    const self = this

    this.$validator.extend('sum100', {

      getMessage(): string {
        return 'Anteile müssen zusammen 100% ergeben.'
      },

      validate(): RuleResult {
        const sum = self.itinerary.legs.reduce((acc, l) => acc + l.share, 0)
        return sum === 100
      },
    })

    this.$validator.extend('requiredCountry', {

      getMessage(): string {
        return 'Land muss ausgewählt sein.'
      },

      validate(val, other): RuleResult {
        if (other && Array.isArray(other)) {
          const otherName = other[0] as string
          const otherVals = self.$refs[otherName]
          if (Array.isArray(otherVals)) {
            const otherVal = otherVals[0]
            // @ts-ignore
            return val === 0 || otherVal.value !== Guid.empty()
          }
        }
        return false
      },
    })
  }
}
