import Address from '@/locations/models/address'
import Country from '@/locations/models/country'
import countriesService from '@/locations/services/countries-service'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import Vuetify, { VCombobox } from 'vuetify/lib'

@Component
export default class CountrySelect extends Vue {

  @Prop()
  readonly value!: string

  @Prop({ default: false })
  readonly filled!: boolean

  @Prop({ default: true })
  readonly outlined!: boolean

  @Prop()
  readonly vValidate!: string

  @Prop({ default: true })
  readonly hideDetails!: boolean

  private items: Country[] = []
  private search = ''
  private country: Country | null = this.select(this.value)

  @Watch('value')
  private valueChanged(val: string, old: string) {
    if (val !== old) {
      this.country = this.select(val)
    }
  }

  @Watch('country')
  private countryChanged(val: Country, old: Country) {
    if (val && old && val.code !== old.code) {
      this.$emit('input', val.code)
    }
  }

  private filter(item: Country, queryText: string, itemText: string) {
    return item.name.toLowerCase().indexOf(queryText.toLowerCase()) > -1
  }

  private select(code: string) {
    if (this.items) {
      const val = this.items.find((c) => c.code === code)
      if (val) {
        return val
      }
    }
    return null
  }

  get isOutlined() {
    return this.outlined === true ? true : false
  }

  get isFilled() {
    return this.filled === true ? true : false
  }

  get doHideDetails() {
    return this.hideDetails === true ? true : false
  }

  private created() {
    this.items = countriesService.findAllAsync()
  }

  private mounted() {
    this.country = this.select(this.value)
  }
}
